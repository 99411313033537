const style = [
  {
    id: 'directions-route-line-alt',
    type: 'line',
    source: 'directions',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#61f984',
      'line-width': 4,
    },
    filter: [
      'all',
      ['in', '$type', 'LineString'],
      ['in', 'route', 'alternate'],
    ],
  },
  {
    id: 'directions-route-line-casing',
    type: 'line',
    source: 'directions',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#61f984',
      'line-width': 2,
    },
    filter: ['all', ['in', '$type', 'LineString'], ['in', 'route', 'selected']],
  },
  {
    id: 'directions-route-line',
    type: 'line',
    source: 'directions',
    layout: {
      'line-cap': 'butt',
      'line-join': 'round',
    },
    paint: {
      'line-color': {
        property: 'congestion',
        type: 'categorical',
        default: '#61f984',
        stops: [
          ['unknown', '#4882c5'],
          ['low', '#4882c5'],
          ['moderate', '#f09a46'],
          ['heavy', '#e34341'],
          ['severe', '#8b2342'],
        ],
      },
      'line-width': 7,
    },
    filter: ['all', ['in', '$type', 'LineString'], ['in', 'route', 'selected']],
  },
  {
    id: 'directions-hover-point-casing',
    type: 'circle',
    source: 'directions',
    paint: {
      'circle-radius': 0,
      'circle-color': '#fff',
    },
    filter: ['all', ['in', '$type', 'Point'], ['in', 'id', 'hover']],
  },
  {
    id: 'directions-hover-point',
    type: 'circle',
    source: 'directions',
    paint: {
      'circle-radius': 0,
      'circle-color': '#3bb2d0',
    },
    filter: ['all', ['in', '$type', 'Point'], ['in', 'id', 'hover']],
  },
  {
    id: 'directions-waypoint-point-casing',
    type: 'circle',
    source: 'directions',
    paint: {
      'circle-radius': 0,
      'circle-color': '#fff',
    },
    filter: ['all', ['in', '$type', 'Point'], ['in', 'id', 'waypoint']],
  },
  {
    id: 'directions-waypoint-point',
    type: 'circle',
    source: 'directions',
    paint: {
      'circle-radius': 0,
      'circle-color': '#8a8bc9',
    },
    filter: ['all', ['in', '$type', 'Point'], ['in', 'id', 'waypoint']],
  },
  {
    id: 'directions-origin-point',
    type: 'circle',
    source: 'directions',
    paint: {
      'circle-radius': 0,
      'circle-color': '#3bb2d0',
    },
    filter: ['all', ['in', '$type', 'Point'], ['in', 'marker-symbol', 'A']],
  },
  {
    id: 'directions-origin-label',
    type: 'symbol',
    source: 'directions',
    layout: {
      'text-field': 'A',
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-size': 0,
    },
    paint: {
      'text-color': '#fff',
    },
    filter: ['all', ['in', '$type', 'Point'], ['in', 'marker-symbol', 'A']],
  },
  {
    id: 'directions-destination-point',
    type: 'circle',
    source: 'directions',
    paint: {
      'circle-radius': 0,
      'circle-color': '#8a8bc9',
    },
    filter: ['all', ['in', '$type', 'Point'], ['in', 'marker-symbol', 'B']],
  },
  {
    id: 'directions-destination-label',
    type: 'symbol',
    source: 'directions',
    layout: {
      'text-field': 'B',
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
      'text-size': 0,
    },
    paint: {
      'text-color': '#fff',
    },
    filter: ['all', ['in', '$type', 'Point'], ['in', 'marker-symbol', 'B']],
  },
]

export default style
